// frontend/src/App.tsx
import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  useLocation,
} from "react-router-dom";
import { AuthProvider, useAuth } from "./components/AuthContext";
import LoadingSpinner from "./components/LoadingSpinner";
import ErrorBoundary from "./components/ErrorBoundary";
import "./App.css";

// Import all components
import Landing from "./components/Landing";
import UserProfile from "./components/UserProfile";
import QuizMasterLoginPage from "./components/QuizMasterLoginPage";
import QuizMasterRegisterPage from "./components/QuizMasterRegisterPage";
import QuizMasterUserDashboard from "./components/QuizMasterUserDashboard";
import QuizMasterCertifications from "./components/QuizMasterCertifications";
import QuizMasterLeaderboard from "./components/QuizMasterLeaderboard";
import QuizMasterCert from "./components/QuizMasterCert";
import QuizMasterTakeQuiz from "./components/QuizMasterTakeQuiz";
import QuizResults from "./components/QuizResults";
import About from "./components/About";
import CertificationGenerator from "./components/CertificationGenerator";
import CheckEmailPage from "./components/CheckEmailPage";
import AllCertifications from "./components/AllCertifications";
import LearnerProfilePage from "./components/LearnerProfilePage";
import Slideshow from "./components/Slideshow";
import DomainKeyConcepts from "./components/DomainKeyConcepts";
import KeyConceptView from "./components/KeyConceptView";
import MatchingGame from "./components/MatchingGame";
import CVSSInfoGraphic from "./components/CVSSInfoGraphic";
import CVSSInfoGraphicGame from "./components/CVSSChallengeGame";
import FlashCards from "./components/FlashCards";

// Protected Route Component
const PrivateRoute: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { isAuthenticated, isLoading } = useAuth();
  const location = useLocation();

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (!isAuthenticated) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return <>{children}</>;
};

// Public Route Component (accessible only when not authenticated)
const PublicRoute: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { isAuthenticated, isLoading } = useAuth();
  const location = useLocation();

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (isAuthenticated) {
    return <Navigate to="/dashboard" replace />;
  }

  return <>{children}</>;
};

function AppContent() {
  const { isLoading } = useAuth();

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <Routes>
      {/* Public routes (accessible without authentication) */}
      <Route path="/about" element={<About />} />
      <Route path="/check-email" element={<CheckEmailPage />} />
      <Route path="/certifications" element={<AllCertifications />} />
      <Route path="/matching" element={<MatchingGame />} />
      <Route path="/info-graphic" element={<CVSSInfoGraphic />} />
      <Route path="/info-quiz" element={<CVSSInfoGraphicGame />} />
      <Route path="/flashcards" element={<FlashCards />} />
      <Route path="/slideshow/:conceptId" element={<Slideshow />} />
      <Route path="/learners" element={<LearnerProfilePage />} />
      <Route path="/domain/:domainId" element={<DomainKeyConcepts />} />
      <Route path="/key-concept/:conceptId" element={<KeyConceptView />} />
      <Route
        path="/certifications/:categoryId"
        element={<QuizMasterCertifications />}
      />
      <Route path="/cert/:certId" element={<QuizMasterCert />} />

      {/* Authentication routes */}
      <Route
        path="/login"
        element={
          <PublicRoute>
            <QuizMasterLoginPage />
          </PublicRoute>
        }
      />
      <Route
        path="/register"
        element={
          <PublicRoute>
            <QuizMasterRegisterPage />
          </PublicRoute>
        }
      />

      {/* Protected routes (require authentication) */}
      <Route
        path="/dashboard"
        element={
          <PrivateRoute>
            <QuizMasterUserDashboard />
          </PrivateRoute>
        }
      />
      <Route
        path="/profile"
        element={
          <PrivateRoute>
            <UserProfile />
          </PrivateRoute>
        }
      />
      <Route
        path="/leaderboard"
        element={
          <PrivateRoute>
            <QuizMasterLeaderboard />
          </PrivateRoute>
        }
      />
      <Route
        path="/quiz/:id/results"
        element={
          <PrivateRoute>
            <QuizResults />
          </PrivateRoute>
        }
      />
      <Route
        path="/quiz/take"
        element={
          <PrivateRoute>
            <QuizMasterTakeQuiz />
          </PrivateRoute>
        }
      />
      <Route
        path="/00admin/certifications"
        element={
          <PrivateRoute>
            <CertificationGenerator />
          </PrivateRoute>
        }
      />

      {/* Landing page / root route */}
      <Route
        path="/"
        element={
          <PublicRoute>
            <Landing />
          </PublicRoute>
        }
      />

      {/* Catch all route - redirect to dashboard if authenticated, otherwise to landing */}
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  );
}

function App() {
  return (
    <ErrorBoundary>
      <AuthProvider>
        <Router>
          <div className="App">
            <AppContent />
          </div>
        </Router>
      </AuthProvider>
    </ErrorBoundary>
  );
}

export default App;
